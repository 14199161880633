import React, { useState } from "react"
import { graphql } from "gatsby"
import { useRequest } from "ahooks"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, Input, TextArea } from "../components/inputs"
import { Message, PageTitle } from "../components/display"
import { useForm } from "react-hook-form"

const submitContact = ({ name, email, phone, message }) => {
  const formData = new FormData()
  formData.append("form-name", "contact")
  formData.append("name", name)
  formData.append("email", email)
  formData.append("phone", phone)
  formData.append("message", message)
  return fetch("/", {
    method: "POST",
    body: formData,
  })
}

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [submitted, setSubmitted] = useState(false)
  const [submitErrored, setSubmitErrored] = useState(false)
  const { loading, run } = useRequest(submitContact, {
    manual: true,
    onSuccess: result => {
      if (result.ok) {
        setSubmitErrored(false)
        setSubmitted(true)
        reset()
      } else {
        setSubmitErrored(true)
        setSubmitted(false)
      }
    },
  })

  return (
    <form
      method="POST"
      name="contact"
      data-netlify="true"
      onSubmit={handleSubmit(data => run(data))}
    >
      <input hidden name="form-name" value="contact" />
      <div className="mb-4">
        <Input
          placeholder="Name (Required)"
          type="text"
          errored={!!errors.name}
          {...register("name", { required: true })}
        />
      </div>
      <div className="mb-4">
        <Input
          placeholder="E-mail (Required)"
          type="email"
          errored={!!errors.email}
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          })}
        />
      </div>
      <div className="mb-4">
        <Input
          placeholder="Phone (Required)"
          type="tel"
          errored={!!errors.phone}
          {...register("phone", {
            required: true,
            pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
          })}
        />
      </div>
      <div className="mb-4">
        <TextArea
          placeholder="Message (Required)"
          errored={!!errors.message}
          {...register("message", { required: true })}
        />
      </div>
      <div className="flex justify-end items-center">
        <Button type="submit" size="large" disabled={loading || submitted}>
          {loading ? "Submitting" : submitted ? "Submitted" : "Submit"}
        </Button>
      </div>
      {submitErrored && (
        <Message className="mt-4" type="error">
          Cannot submit at the moment. Please try again later.
        </Message>
      )}
    </form>
  )
}

const ContactPage = ({ data, location }) => {
  const { seo, title, form } = data.siteYaml

  return (
    <Layout location={location} title={title}>
      <Seo title={seo.title} description={seo.description} />
      <section className="py-20">
        <div className="container px-4 mx-auto">
          <PageTitle>{title}</PageTitle>
          <div className="flex flex-col flex-wrap w-full max-w-xl mx-auto items-center bg-silver-light py-8 px-4 mb-12">
            <div className="w-full px-3 max-w-4xl">
              <div className="px-4">
                <h2 className="mt-2 text-2xl font-bold font-display text-center">
                  {form.title}
                </h2>
                <p className="mt-4 mb-8 text-jet-light text-center">
                  {form.description}
                </p>
              </div>
            </div>
            <div className="w-full px-3 max-w-4xl">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    siteYaml(type: { eq: "contact-us" }) {
      seo {
        title
        description
      }
      title
      description
      downloadPressKit {
        description
        title
        action {
          fileName
          href
          label
        }
      }
      form {
        title
        description
      }
    }
  }
`
